import classes from './Navbar.module.css'
import { ButtonWithSVG } from '../UI/Button/Button'
import { svgAnimation } from '../../Animations'
import { motion } from 'framer-motion'

const Navbar = () => {
    return (
        <nav className={classes.navbar}>
            <motion.ul variants={svgAnimation}>
                <ButtonWithSVG
                    svgname="Github"
                    title="My Github"
                    href="https://github.com/Shoozle"
                >
                </ButtonWithSVG>
                <ButtonWithSVG
                    svgname="Linkedin"
                    title="My Linkedin"
                    href="https://www.linkedin.com/in/sean-m-339a23209/"
                >
                </ButtonWithSVG>
                <ButtonWithSVG
                    svgname="Email"
                    title="me@seanmurtagh.uk"
                    href="mailto:me@seanmurtagh.uk"
                >
                </ButtonWithSVG>
            </motion.ul>
        </nav>
    )
}

export default Navbar