import React from 'react';
import classes from './Panel.module.css';
import Project from './Project/Project';


const Panel = (props) => {

    const { area, heading, projects } = props;

    const panelClass = [
        classes.panel,
        classes[area]
    ];

    let Projects = [];

    if (Array.isArray(projects)) {
        const singleProject = projects.map((prj, index) => (
            <Project 
                key={index} 
                title={prj.project} 
                description={prj.description} 
                link={prj.link} 
                repo={prj.repo}
                imageUrl={prj.hrefloc}
                tags={prj.tags || ''} 
                size={prj.class || ''}
                />
        ))
        Projects.push(singleProject)
    }

    const pClass = panelClass.join(' ')

    return (
        <section id={heading}>
            <div className={pClass}>
                <h2>{heading}</h2>
                <div className={classes.projects}>
                    {Projects}
                </div>
            </div>
        </section>
    )
}

export default Panel;