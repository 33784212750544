export const headerAnimation = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: .6
        }
    }
}

export const titleAnimation = {
    hidden: {
        x: 200,
        opacity: 0
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeOut'
        }
    }
}

export const svgAnimation = {
    hidden: {
        opacity: 0,
        scale: .6
    },
    show: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: .75,
            ease: 'easeOut'
        }
    }
}