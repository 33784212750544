import React from 'react';
import classes from './Header.module.css'
import Navbar from '../Navbar/Navbar';

import { motion } from 'framer-motion';
import { headerAnimation, titleAnimation } from '../../Animations';

const Header = () => {

    const backgroundStyle = {
        'background': `url(${process.env.PUBLIC_URL}/images/background-park.jpg)`,
        'background-color': `rgba(0, 0, 0, 0.5)`,
        'background-blend-mode': `multiply`,
        'background-size': 'cover',
        'background-position': 'center'
    }

    return (
        <main>
            <section>
                <motion.div variants={headerAnimation} initial="hidden" animate="show">
                    <header className={classes.header} style={backgroundStyle}>
                        <motion.h1 variants={titleAnimation}>
                            Sean Murtagh
                        </motion.h1>
                        <span></span> 
                        <motion.h2
                            variants={titleAnimation}>
                            front-end web developer
                        </motion.h2>
                        <span></span> 
                        <motion.h2
                            variants={titleAnimation}>
                            freelance for multiple clients 
                        </motion.h2>
                        <span></span> 
                        <motion.h2
                            variants={titleAnimation}>
                            5+ years professional experience 
                        </motion.h2>
                        <span></span> 
                        <Navbar />
                    </header>
                </motion.div>
            </section>
        </main>
    )
}

export default Header