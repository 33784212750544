
import Header from './components/Header/Header';
import Panel from './components/Panel/Panel';
import Layout from './components/UI/Layout/Layout';
import panels from './assets/json/panels.json';
import { AnimatePresence } from 'framer-motion';
import Footer from './components/Footer/Footer';


function App() {

  const Panels = panels.map((panel, index) => (
    <Panel key={index} area={panel.area} heading={panel.heading} projects={panel.projects} />
  )
  )

  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter>
        <Layout>
          <Header />
          {Panels}
          <Footer />
        </Layout>
      </AnimatePresence>
    </div>
  );
}

export default App;
