import React from 'react';
import { ReactComponent as Email } from '../../../assets/SVG/mail.svg';
import { ReactComponent as Github } from '../../../assets/SVG/github.svg';
import { ReactComponent as Linkedin } from '../../../assets/SVG/linkedin.svg';
import { ReactComponent as Twitter } from '../../../assets/SVG/twitter.svg';

import classes from './Button.module.css';

const ButtonWithSVG = (props) => {

       const svgIcon = {
        fill: 'currentColor',
        width: '2.2rem',
        height: '2.2rem',
        marginRight: '.4rem'
    };

    let svg = null;
    //CREATE STYLE OBJECT AND SET STYLE EQUAL TO IT ON ALL SVG CASES

    switch (props.svgname) {
        case "Email":
            svg = <Email style={svgIcon}> </Email>;
            break;
        case "Github":
            svg = <Github style={svgIcon}> </Github>;
            break;
        case "Linkedin":
            svg = <Linkedin style={svgIcon}> </Linkedin>;
            break;
        case "Twitter":
            svg = <Twitter style={svgIcon}> </Twitter>;
            break;
        default:
            svg = <h1>No Icon</h1>;
    }

    return (
        <li className={classes.Button}>
            <a className={classes.SVGLink} href={props.href} target="_blank" rel="noreferrer">
                {svg}
            </a>
        </li>
    )
};

const ButtonLink = (props) => (
        <a className={classes.Link} href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
);


export {
    ButtonWithSVG,
    ButtonLink
}