import React from 'react';
import classes from './Project.module.css';
import { ButtonLink } from '../../UI/Button/Button'
import { useInView } from 'react-intersection-observer';

const Project = (props) => {

    const { title, description, link, imageUrl, size, repo } = props;
    
    const { ref, inView } = useInView()

    let inViewClass = inView ? classes.inView : classes.notInView;
    let sizeClass = size ? classes.small : '';
    let tags;

    props.tags ? tags = props.tags.map(tag => (<li key={tag}>{tag}</li>)) : tags = '';

    return (
        <div className={[classes.Project, inViewClass, sizeClass].join(' ')} ref={ref}>
            <h3>{title}</h3>
            <div className={classes.imgContainer}>
                <img className="panelImg" src={`${process.env.PUBLIC_URL}/images/${imageUrl}`} alt={`${title}`}></img>
            </div>
            <p>{description}</p>
            <ul className={classes.tags}>
                {tags}
            </ul>
            <div>
                <ButtonLink href={link}>Visit site</ButtonLink>
                {repo && <ButtonLink href={repo}>Github Repo</ButtonLink>}
            </div>
            <div className={classes.stripe}>
                
            </div>
        </div>
    )
}

export default Project;