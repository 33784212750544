import './Footer.module.css';

const Footer = () => {

    return (
        <footer>
            <a href="mailto:me@seanmurtagh.uk?subject=Hello Sean">me@seanmurtagh.uk</a>
            <p>Sean Murtagh</p>
        </footer>
    )

}

export default Footer